import React from 'react'
import {Container, Box} from '@material-ui/core'
import { convertPrefectureValueToLabel } from '../../helper/common';

const BuyerInfoDisplayComponent = (props) => {
  const {buyerInfo, classes} = props;
  return (
    <Box my="64px">
      <Container maxWidth="sm" my="64px">
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan="2" className={classes.th}>購入者の情報</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.label}>氏名</td>
              <td>{buyerInfo.name}</td>
            </tr>
            <tr>
              <td className={classes.label}>郵便番号</td>
              <td>{buyerInfo.postalCode}</td>
            </tr>
            <tr>
              <td className={classes.label}>都道府県</td>
              <td>{convertPrefectureValueToLabel(buyerInfo.prefecture)}</td>
            </tr>
            <tr>
              <td className={classes.label}>市区町村/番地</td>
              <td>{buyerInfo.city}</td>
            </tr>
            <tr>
              <td className={classes.label}>アパート・<br/>マンション名</td>
              <td>{buyerInfo.building? buyerInfo.building : "-"}</td>
            </tr>
            <tr>
              <td className={classes.label}>電話番号</td>
              <td>{buyerInfo.tel}</td>
            </tr>
            <tr>
              <td className={classes.label}>メールアドレス</td>
              <td>{buyerInfo.email}</td>
            </tr>
            <tr>
              <td className={classes.label}>備考</td>
              <td>{buyerInfo.remarks}</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Box>
  )
}

export default BuyerInfoDisplayComponent