import React from 'react' 
import { getProductFromId } from '../../helper/common';
import OrderedProductCardComponent from '../common/orderd_product_card_component'

const CartConfirmProductListComponent = (props) => {
  const {products, isDevicePc} = props;
  const contents = Object.keys(products).map((id, i) => {
    const product = getProductFromId(id);
    const count = products[id];
    return(
      <OrderedProductCardComponent product={product} count={count} isDevicePc={isDevicePc} key={i}/>
    )
  })
  return (
    <div>
      {contents}
    </div>
  )
};

export default CartConfirmProductListComponent