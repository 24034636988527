import React from 'react'
import { Button, Container, Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classes from '../../styles/app/thanks.module.scss'

const ErrorContainer = (props) => {
  return (
    <Box py="40px">
      <Container maxWidth="md">
        <Box mb="40px">
          <Typography variant="h2" align="center">送信に失敗しました。</Typography>
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto" lineHeight="1.7">
          申し訳ございませんが、
          <br />少し時間をおいて、もう一度送信していただくようよろしくお願いいたします。
          <br />なお、何度もエラーが起こる場合は下記連絡先までご連絡ください。
          <br/>
          <br />090-9731-4337
          <br />akiyamaen3147@yahoo.co.jp
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto">
          <Link to={"/cart"}>
            <Button variant="outlined" color="primary" className={classes.buttonBig}>カートへ戻る</Button>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default ErrorContainer