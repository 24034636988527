import { CONFIRM_BUYER_INFO, INIT_BUYER_INFO } from "../actions/action_types";

const initialState = {
    name: '',
    postalCode: '',
    prefecture: '',
    city: '',
    building: '',
    tel: '',
    email: '',
    remarks: ''
  };

const buyerInfo = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_BUYER_INFO: {
      const {buyerInfo} = action.payload;
      let newState = {...state}
      newState = buyerInfo;
      return newState
    }
    case INIT_BUYER_INFO: {
      return initialState;
    }
    default:
      return state;
  }
}

export default buyerInfo