import React, { useEffect } from 'react' 
import { connect } from 'react-redux'
import CartConfirmProductListComponent from '../../components/confirm/cart_confirm_product_list'
import BuyerInfoDisplayComponent from '../../components/common/buyer_info_display_component'
import ShippingInfoDisplayComponent from '../../components/common/shipping_info_display_component'
import {Button, Container, Box, Typography, Divider} from '@material-ui/core'
import {Link} from 'react-router-dom'
import classes from '../../styles/app/thanks.module.scss'
import SectionTitleComponent from '../../components/common/section_title_component'
import { initCart } from "../../actions/product_action"
import {initBuyerInfo} from "../../actions/buyer_action"
import { initShippingInfo } from "../../actions/shipping_action";
import { calculatePostage } from '../../helper/common'
import { calculateTotal } from '../../helper/common';
import { calculateSubtotal } from '../../helper/common';

function mapStateToProps(state) {
  return {
    products: state.cartContents,
    buyerInfo: state.buyerInfo,
    shippingInfo: state.shippingInfo
  }
};

function mapDispatchToProps(dispatch) {
  return {
    initCart: () => { dispatch(initCart()) },
    initBuyerInfo: () => { dispatch(initBuyerInfo()) },
    initShippingInfo: () => { dispatch(initShippingInfo()) },
  }
};

const ThanksContainer = (props) => {
  const {products, buyerInfo, shippingInfo} = props;
  const {initCart, initBuyerInfo, initShippingInfo} = props;
  useEffect(() => {
    return () => {
      initCart();
      initBuyerInfo();
      initShippingInfo();
    }
  })
  return (
    <Box py="40px">
      <Container maxWidth="md">
        <Box mb="40px">
          <Typography variant="h2" align="center">ご注文ありがとうございます！</Typography>
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto" lineHeight="1.7">
          <p>
            下記の内容でご注文を承りました。
            <br />注文完了メール（または下記）に記載されている口座にお振込をお願いいたします。
            <br />ご入金が確認でき次第、商品を発送いたします。
          </p>
          <p>
            あきやま園から美味しい梨が届くまで、もうしばらくお待ちいただきますよう、よろしくお願いいたします。
            <br />なお、注文完了メールが届かない場合は下記連絡先までご連絡ください。
            <br />また、ご質問等ございます場合も下記連絡先で承っております。
            <br />090-9731-4337
            <br />akiyamaen3147@yahoo.co.jp<br/>
          </p>
          <p>
            ーーーーーーお振込先情報ーーーーーー
            <br />【ゆうちょ銀行以外からのお振込】
            <br />銀行名：ゆうちょ銀行
            <br />金融機関コード：9900
            <br />店番：528
            <br />預金種目：普通
            <br />店名：五二八店（ゴニハチ店）
            <br />口座番号：0202733
            <br />口座名義人：秋山宏樹（アキヤマヒロキ）
            <br /><br />【ゆうちょ銀行からのお振込】
            <br />記号：15290
            <br />番号：02027331
          </p>
          

          
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto">
          <Link to={"/"}>
            <Button variant="outlined" color="primary" className={classes.buttonBig}>TOPへ戻る</Button>
          </Link>
        </Box>
        <SectionTitleComponent title="ご注文内容" />
        <CartConfirmProductListComponent products={products} />
        <Box mx="auto" textAlign="right">
          <Box>
            小計：￥{calculateSubtotal(products).toLocaleString()}<br />
            配送料：￥{calculatePostage(products, shippingInfo.postalCode, shippingInfo.prefecture).toLocaleString()}
          </Box>
          <Divider />
          <Box fontSize="24px" fontWeight="700">
            合計：￥{calculateTotal(products, shippingInfo.postalCode, shippingInfo.prefecture).toLocaleString()}
          </Box>
        </Box>
        <BuyerInfoDisplayComponent buyerInfo={buyerInfo} classes={classes} />
        <ShippingInfoDisplayComponent shippingInfo={shippingInfo} classes={classes} />
      </Container>
    </Box>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThanksContainer)