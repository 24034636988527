const Products = {
  pear: {
    name: "梨",
    note: "",
//    note: "個数について：3kgは6～8個、5kgは12～16個の梨がはいっています。",
    varieties: [
      {
        id: 101,
        label: "akibae3k",
        name: "秋栄　3kg",
        price: 3000,
        description: "鳥取大学で生まれたあまくてジューシーな梨です。蜜入り。",
        harvestTime: "9月上旬",
        imagePath: "/img/akibae.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 102,
        label: "hosui3k",
        name: "豊水　3kg",
        price: 3000,
        description: "1972年命名　全国的に、甘さ・香りで人気です。",
        harvestTime: "9月上～中旬",
        imagePath: "/img/housui.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 103,
        label: "hosui5k",
        name: "豊水　5kg",
        price: 4000,
        description: "1972年命名　全国的に、甘さ・香りで人気です。",
        harvestTime: "9月上～中旬",
        imagePath: "/img/housui.png",
        weight: 5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 104,
        label: "nijisseki3k",
        name: "二十世紀　3kg",
        price: 3000,
        description: "鳥取県のお奨め。美人！さわやか！。",
        harvestTime: "9月10日～中旬",
        imagePath: "/img/nijusseiki.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 105,
        label: "nijisseki5k",
        name: "二十世紀　5kg",
        price: 4000,
        description: "鳥取県のお奨め。美人！さわやか！",
        harvestTime: "9月10日～中旬",
        imagePath: "/img/nijusseiki.png",
        weight: 5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 106,
        label: "shinkansen3k",
        name: "新甘泉　3kg",
        price: 3000,
        description: "鳥取県が育んだ新しいおいしさ、甘さ。年齢問わず人気。",
        harvestTime: "9月10日～中旬",
        imagePath: "/img/pears.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 107,
        label: "akiduki3k",
        name: "あきづき　3kg",
        price: 3000,
        description: "名月のごとく美しい丸さ。甘く果肉サク！",
        harvestTime: "9月中旬",
        imagePath: "/img/akiduki.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 108,
        label: "akiduki5k",
        name: "あきづき　5kg",
        price: 4000,
        description: "名月のごとく美しい丸さ。甘く果肉サク！",
        harvestTime: "9月中旬",
        imagePath: "/img/akiduki.png",
        weight: 5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 109,
        label: "akikansen3k",
        name: "秋甘泉　3kg",
        price: 3000,
        description: "新甘泉の後に続く、甘さ豊かな果実の泉。",
        harvestTime: "9月中旬",
        imagePath: "/img/pears.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 110,
        label: "akikansen5k",
        name: "秋甘泉　5kg",
        price: 4000,
        description: "新甘泉の後に続く、甘さ豊かな果実の泉。",
        harvestTime: "9月中旬",
        imagePath: "/img/pears.png",
        weight: 5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 111,
        label: "omakase3k",
        name: "おまかせ二種セット　3kg",
        price: 3000,
        description: "注文時に完熟している美味しい梨をお届けします。",
        harvestTime: "9月上旬～",
        imagePath: "/img/pears.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 112,
        label: "ohsyu3k",
        name : "王秋 3kg",
        price: 3000,
        description: "ラグビーボールみたいな形は他にありません！日持ちの良い甘い赤梨",
        harvestTime: "11月上旬",
        imagePath: "/img/pears.png",
        weight: 3,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 113,
        label: "ohsyu5k",
        name : "王秋 5kg",
        price: 4500,
        description: "ラグビーボールみたいな形は他にありません！日持ちの良い甘い赤梨",
        harvestTime: "11月上旬",
        imagePath: "/img/pears.png",
        weight: 5,
        size: "6〜10個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 114,
        label: "ohsyu7k500m",
        name : "王秋 7.5kg",
        price: 5300,
        description: "ラグビーボールみたいな形は他にありません！日持ちの良い甘い赤梨",
        harvestTime: "11月上旬",
        imagePath: "/img/pears.png",
        weight: 7.5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 117,
        label: "shinsetu3k",
        name : "新雪 3kg",
        price: 2500,
        description: "お歳暮用のぴったりのデカさ。日持ち良し。酸味無し。",
        harvestTime: "12月上旬",
        imagePath: "/img/pears.png",
        weight: 3,
        size: "3〜4個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 118,
        label: "shinsetu5k",
        name : "新雪 5kg",
        price: 4000,
        description: "お歳暮用のぴったりのデカさ。日持ち良し。酸味無し。",
        harvestTime: "12月上旬",
        imagePath: "/img/pears.png",
        weight: 5,
        size: "5〜6個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 115,
        label: "shinsetu4k",
        name : "新雪 4kg",
        price: 3600,
        description: "お歳暮用のぴったりのデカさ。日持ち良し。酸味無し。",
        harvestTime: "12月上旬",
        imagePath: "/img/pears.png",
        weight: 4,
        size: "5〜6個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 116,
        label: "shinsetu8k",
        name : "新雪 8kg",
        price: 5500,
        description: "お歳暮用のぴったりのデカさ。日持ち良し。酸味無し。",
        harvestTime: "12月上旬",
        imagePath: "/img/pears.png",
        weight: 8,
        size: "8~9個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
    ]
  },
  persimmon: {
    name: "柿",
    note: "",
    varieties: [
      {
        id: 201,
        label: "kirato3k",
        name : "輝太郎 3kg",
        price: 3500,
        description: "鳥取県の生んだ傑作。果肉優しく、きれい。鬼太郎もびっくり!?",
        harvestTime: "10月上旬",
        imagePath: "/img/kitarou.png",
        weight: 3,
        size: "12個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 202,
        label: "kirato5k",
        name : "輝太郎 5kg",
        price: 4500,
        description: "鳥取県の生んだ傑作。果肉優しく、きれい。鬼太郎もびっくり!?",
        harvestTime: "10月上旬",
        imagePath: "/img/kitarou.png",
        weight: 5,
        size: "18個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 203,
        label: "saijo3k",
        name : "西条柿 3kg",
        price: 3500,
        description: "渋柿を72時間かけて甘くして発送します。これはなかなか口に入らない逸品",
        harvestTime: "10月中旬",
        imagePath: "/img/saijogaki.png",
        weight: 3,
        size: "15〜18個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 204,
        label: "saijo5k",
        name : "西条柿 5kg",
        price: 4500,
        description: "渋柿を72時間かけて甘くして発送します。これはなかなか口に入らない逸品",
        harvestTime: "10月中旬以降",
        imagePath: "/img/saijogaki.png",
        weight: 5,
        size: "25〜28個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 205,
        label: "saijo10k",
        name : "西条柿 10kg",
        price: 8000,
        description: "渋柿を72時間かけて甘くして発送します。これはなかなか口に入らない逸品",
        harvestTime: "10月中旬",
        imagePath: "/img/saijogaki.png",
        weight: 10,
        size: "50〜55個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 206,
        label: "turushi5k",
        name : "吊るし柿用",
        price: 3000,
        description: "皮をむいて干しやすい紐付き・軸付き",
        harvestTime: "10月中旬",
        imagePath: "/img/turusigaki.png",
        weight: 5,
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 207,
        label: "turushi12k",
        name : "吊るし柿用",
        description: "皮をむいて干しやすい紐付き・軸付き",
        price: 4200,
        harvestTime: "10月中旬以降",
        imagePath: "/img/turusigaki.png",
        weight: 12,
        size: "60個",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 208,
        label: "hanagosho3k",
        name : "花御所 3kg",
        price: 3500,
        description: "果肉が綿密で甘く何とも言えないとろり感?があります。ご賞味あれ",
        harvestTime: "11月下旬",
        imagePath: "/img/hanagosho.png",
        weight: 3,
        size: "12個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 209,
        label: "hanagosho5k",
        name : "花御所 5kg",
        price: 4500,
        description: "果肉が綿密で甘く何とも言えないとろり感?があります。ご賞味あれ",
        harvestTime: "11月下旬",
        imagePath: "/img/hanagosho.png",
        weight: 5,
        size: "20個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 210,
        label: "hanagosho10k",
        name : "花御所 10kg",
        price: 8000,
        description: "果肉が綿密で甘く何とも言えないとろり感?があります。ご賞味あれ",
        harvestTime: "11月下旬",
        imagePath: "/img/hanagosho.png",
        weight: 10,
        size: "大20個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      },
      {
        id: 211,
        label: "fuyu5k",
        name : "富有柿 5kg",
        price: 3000,
        description: "鳥取のオリジナルではありませんが、樹上完熟にこだわっています",
        harvestTime: "11月中旬",
        imagePath: "/img/huyuugaki.png",
        weight: 5,
        size: "18個程度",
        isSoldOut: true,
        isDisplayed: true,
        isTottoriIchi: true,
      },
      {
        id: 212,
        label: "fuyu10k",
        name : "富有柿 10kg",
        price: 5000,
        description: "鳥取のオリジナルではありませんが、樹上完熟にこだわっています",
        harvestTime: "11月中旬",
        imagePath: "/img/huyuugaki.png",
        weight: 10,
        size: "35個程度",
        isSoldOut: true,
        isDisplayed: false,
        isTottoriIchi: true,
      }
    ]
  }
}



export default Products
