import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6E9B48',
    },
    secondary: {
      main: '#F3A60F',
    },
    textSecondary: {
      main: '#DF9400',
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      "メイリオ",
      "Meiryo",
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700
    },
    h3: {
      fontSize: 32,
      fontWeight: 700
    },
    h4: {
      fontSize: 32,
    },
  },
  overrides: {
    // セレクトボックスの高さ調整
    MuiSelect: {
      root: {
        paddingTop: 10.5,
        paddingBottom: 10.5
      }
    },
  },
});
