import React from 'react'
import {Box, Typography, Divider} from '@material-ui/core'

const SectionTitleComponent = (props) => {
  const {title} = props;
  return (
    <Box mb="24px" fontWeight="bold">
      <Typography variant="h2" component="h2" align="center">
        {title}
      </Typography>
      <Divider />
    </Box>
  )
};

export default SectionTitleComponent