import React from 'react'
import { Button, Container, Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classes from '../../styles/app/thanks.module.scss'

const NotFoundContainer = (props) => {
  return (
    <Box py="40px">
      <Container maxWidth="md">
        <Box mb="40px">
          <Typography variant="h2" align="center">ページがありません。</Typography>
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto" lineHeight="1.7">
          申し訳ございません、お探しのページがありません。
          <br />下記ボタンからトップページへ移動できます。
          <br />
        </Box>
        <Box maxWidth="640px" my="40px" mx="auto">
          <Link to={"/"}>
            <Button variant="outlined" color="primary" className={classes.buttonBig}>TOPへ戻る</Button>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFoundContainer