import React from 'react'
import {Box} from '@material-ui/core'
import {getProductFromId} from '../../helper/common'
import ProductCardSmallComponent from '../../components/common/product_card_small_component'

const CartContentsListComponent = (props) => {
  const {products, isDevicePc} = props;
  let contents;
  if (Object.keys(products).length < 1) {
    contents = (
      <Box>
        カートに商品が入っていません
      </Box>
    )
  } else {
    contents = Object.keys(products).map((id, i) => {
      const product = getProductFromId(id);
      const count = products[id];
      return (
        <ProductCardSmallComponent
          product={product}
          count={count}
          addProductToCart={props.updateCartContents}
          deleteProductFromCart={props.deleteProductFromCart}
          key={i}
          isDevicePc={isDevicePc}
        />
      )
    });
  }
  return (
    <Box>
      {contents}
    </Box>
  )
};

export default CartContentsListComponent