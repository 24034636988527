import React from 'react'
import { MenuItem, Select } from '@material-ui/core'
import Prefectures from '../../data/prefectures'
import { useFormContext, Controller } from "react-hook-form";

const PrefectureSelectComponent = (props) => {
  const { classes, value, name } = props;
  const { errors } = useFormContext();

  const menuItem = Prefectures.map((v, i) => {
  return <MenuItem value={v.value} key={i}>{v.label}</MenuItem>
  })
  return (
    <>
      <Controller
        name={name}
        defaultValue={value}
        rules={{ required: true }}
        error={errors.prefecture ? true : false}
        variant="outlined"
        className={classes.inputMd}
        as={
          <Select>
            {menuItem}
          </Select>
        }
      />
    </>
  )
};

export default PrefectureSelectComponent