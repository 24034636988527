import React from 'react'
import {Card, Box, CardMedia, CardContent, CardActions, Typography} from '@material-ui/core'
import classes from '../../styles/app/components/ordered_product_card.module.scss'

const OrderedProductCardComponent = (props) => {
  const {product, isDevicePc} = props;
  const count = props.count;
  return(
    <Card className={classes.card} variant="outlined">
      <Box display="flex" alignItems={isDevicePc? "center" : "flex-start"} justifyContent="space-between" flexDirection={isDevicePc? "row" : "column"}>
        <Box display="flex" alignItems="center">
          <CardMedia
            className={classes.cardMedia}
            image={product.imagePath}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography component="h5" variant="h5">
              {product.name}
            </Typography>
            <Typography variant="h5">
              ¥ {product.price.toLocaleString()}
            </Typography>
          </CardContent>
        </Box>
        <CardActions className={classes.cardActions}>
          <Typography variant="body2" display="inline">数量：{count}</Typography>
        </CardActions>
      </Box>
    </Card>
  )
};

export default OrderedProductCardComponent