import React, {useState} from 'react'
import { TextField } from '@material-ui/core'
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Box, MenuItem } from '@material-ui/core';
import classes from '../../styles/app/components/product_card.module.scss'

const ProductCardComponent = (props) => {
  const [count, setCount] = useState('0');
  const addProductToCart = (product, count) => {
    props.addProductToCart(product, count);
  };
  let cardAction;
  if (props.product.isTottoriIchi) {
    cardAction = (
      <Button
        variant="contained"
        color="primary"
       className={classes.cardLink}
      >
        <a href="https://tottori-ichi.jp/tenpo_syouhin_list.php?id=861">購入はこちらから</a>
      </Button>
    )
  } else if (props.product.isSoldOut) {
    cardAction = (
      <Button
        variant="contained"
        color="primary"
        className={classes.cardButton}
        disableElevation
        disabled
      >
        完売しました
      </Button>
    )
  }　else {
    cardAction = (
      <>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Typography variant="body2" display="inline">数量：</Typography>
          <TextField
            className={classes.cardInput}
            id="select outlined-size-small"
            variant="outlined"
            size="small"
            value={count}
            onChange={(e) => setCount(e.target.value)}
            select
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </TextField>
        </Box>
        <Button
          variant="contained"
          color="primary"
          className={classes.cardButton}
          disableElevation
          onClick={() => addProductToCart(props.product, count)}
          disabled={count === '0' || (props.count + +count) > 5}
        >
          カートに追加
        </Button>
      </>
    )
  }

  return (
    <Card variant="outlined">
      <CardMedia
        className={classes.cardMedia}
        image={props.product.imagePath}
        title="Contemplative Reptile"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h5">
          {props.product.name}
          <Typography gutterBottom variant="span" component="span">
            {props.product.size}
          </Typography>
        </Typography>
        <Typography gutterBottom variant="body1" component="p">
          {props.product.description}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          収穫時期：{props.product.harvestTime}
        </Typography>
      </CardContent>
      {+props.count > 0 && (
        <Box mx="16px" bgcolor="#FDEDCF">
            <Typography variant="subtitle2" component="p">カートに{props.count}つ入っています</Typography>
        </Box>
      )}
      {+props.count + +count > 5 && (
        <Box mx="16px">
          <Typography variant="subtitle2" component="p" color="error">お一人様5つまで購入可能です</Typography>
        </Box>
      )}
      <CardActions>
        {cardAction}
      </CardActions>
    </Card>
  )
};

export default ProductCardComponent
