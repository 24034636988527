import React from 'react'
import {Box, AppBar, Toolbar, Badge, Typography } from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import classes from '../../styles/app/components/header.module.scss'

const mapStateTOProps = (state) => {
  return {
    cartContents: state.cartContents
  }
}

const HeaderComponent = (props) => {
  const cartContents = props.cartContents;
  const count = Object.keys(cartContents).length;
  return (
    <AppBar position="fixed" color={"inherit"}>
      <Toolbar>
        <Box className={classes.logoWrapper}>
          <Link to={"/"}>
            <img src="/img/logo.svg" className={classes.logo} alt="あきやま園"/>
          </Link>
        </Box>
        <Link to={"/cart"} className={classes.cart}>
          <Box display="flex" flexDirection="column" alignItems="center" pt="12px">
            <Badge badgeContent={count} color="secondary">
              <ShoppingCartIcon color="primary"/>
            </Badge>
            <Typography variant="caption" color="primary">カート</Typography>
          </Box>
        </Link>
      </Toolbar>
    </AppBar>
  )
};

export default connect(
  mapStateTOProps
)(HeaderComponent)