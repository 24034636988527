import React from 'react';
import { BrowserRouter as Router, Route , Switch} from 'react-router-dom';
import TopContainer from './containers/top/top_container';
import CartContainer from './containers/cart/cart_container';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Box} from '@material-ui/core'
import {theme} from './styles/material-ui/theme';
import HeaderComponent from './components/common/header';
import FooterComponent from './components/common/footer';
import ScrollToTop from './containers/scrolll_to_top';
import CartConfirmContainer from './containers/confirm/cart_confirm_container'
import ThanksContainer from './containers/thanks/thanks_container';
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import ErrorContainer from './containers/error/error_container'
import NotFoundContainer from './containers/not-found/not_found_container'

Amplify.configure(awsconfig)

function App() {
  return (
    <MuiThemeProvider theme={theme}>      
      <Router>
        <ScrollToTop>
          <HeaderComponent/>
            <Box pt="56px" className="App">
              <Switch>
                <Route exact path='/' component={TopContainer} />
                <Route exact path='/cart' component={CartContainer} />
                <Route exact path='/confirm' component={CartConfirmContainer} />
                <Route exact path='/thanks' component={ThanksContainer} />
                <Route exact path='/error' component={ErrorContainer} />
                <Route component={NotFoundContainer} />
              </Switch>
            </Box>
          <FooterComponent/>
        </ScrollToTop>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
