// 商品
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const UPDATE_CART_CONTENTS = "UPDATE_CART_CONTENTS";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART";
export const INIT_CART = "INIT_CART";

// 購入者情報
export const CONFIRM_BUYER_INFO = "CONFIRM_BUYER_INFO";
export const INIT_BUYER_INFO = "INIT_BUYER_INFO";

// 配送先情報
export const CONFIRM_SHIPPING_INFO = "CONFIRM_SHIPPING_INFO";
export const INIT_SHIPPING_INFO = "INIT_SHIPPING_INFO";