import React, { useState } from 'react'
import CartConfirmProductListComponent from '../../components/confirm/cart_confirm_product_list'
import { connect } from 'react-redux'
import BuyerInfoDisplayComponent from '../../components/common/buyer_info_display_component'
import ShippingInfoDisplayComponent from '../../components/common/shipping_info_display_component'
import {Button, Box, Container, Divider} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import classes from '../../styles/app/cart-confirm.module.scss'
import SectionTitleComponent from '../../components/common/section_title_component'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { API } from 'aws-amplify'
import { convertCartContentsToBody, calculatePostage } from '../../helper/common'
import { calculateTotal } from '../../helper/common';
import { convertPrefectureValueToLabel } from '../../helper/common';
import { calculateSubtotal } from '../../helper/common';
import { getItemLabels } from '../../helper/common';
import LoadingComponent from '../../components/common/loading_component'

function mapStateToProps(state) {
  return {
    products: state.cartContents,
    buyerInfo: state.buyerInfo,
    shippingInfo: state.shippingInfo
  }
};

const CartConfirmContainer = (props) => {
  // デバイス幅の判定
  const theme = useTheme();
  const isDevicePc = useMediaQuery(theme.breakpoints.up('md'));

  const [isLoading, setIsLoading] = useState(false);

  const pageBack = () => {
    props.history.push('/cart');
  }

  const postItem = async body => {
    const APIName = 'apib50b0813'
    const path = '/api/order-item'
    const params = {
      body: body,
    }
    return await API.post(APIName, path, params)
  }
  
  const order = async () => {
    try {
      setIsLoading(true);
      let body = {
        "clientName": buyerInfo.name,
        "clientPostalCode": buyerInfo.postalCode,
        "clientAddressPref": convertPrefectureValueToLabel(buyerInfo.prefecture),
        "clientAddressDetail": buyerInfo.city,
        "clientBuildingName": buyerInfo.building,
        "clientPhoneNumber": buyerInfo.tel,
        "clientMail": buyerInfo.email,
        "customerName": shippingInfo.name,
        "customerPostalCode": shippingInfo.postalCode,
        "customerAddressPref": convertPrefectureValueToLabel(shippingInfo.prefecture),
        "customerAddressDetail": shippingInfo.city,
        "customerBuildingName": shippingInfo.building,
        "customerPhoneNumber": shippingInfo.tel,
        "akibae3kNum": 0,
        "hosui3kNum": 0,
        "hosui5kNum": 0,
        "nijisseki3kNum": 0,
        "nijisseki5kNum": 0,
        "shinkansen3kNum": 0,
        "akiduki3kNum": 0,
        "akiduki5kNum": 0,
        "akikansen3kNum": 0,
        "akikansen5kNum": 0,
        "omakase3kNum": 0,
        "kirato3kNum": 0,
        "kirato5kNum": 0,
        "saijo3kNum": 0,
        "saijo5kNum": 0,
        "saijo10kNum": 0,
        "turushi5kNum": 0,
        "turushi12kNum": 0,
        "hanagosho3kNum": 0,
        "hanagosho5kNum": 0,
        "hanagosho10kNum": 0,
        "ohsyu3kNum": 0,
        "ohsyu5kNum": 0,
        "ohsyu7k500mNum": 0,
        "shinsetu4kNum": 0,
        "shinsetu8kNum": 0,
        "fuyu5kNum": 0,
        "fuyu10kNum": 0,
        "totalPayment": calculateTotal(products, shippingInfo.postalCode, shippingInfo.prefecture),
        "message": buyerInfo.remarks,
        "itemLabels": getItemLabels(products)
      }
      const productList = convertCartContentsToBody(products);
      body = {...body, ...productList};
      
      await postItem(body)
      setIsLoading(false);
      props.history.push('/thanks');
    } catch (e) {
      props.history.push('/error');
    }
  }

  const { products, buyerInfo, shippingInfo } = props;
  return (
    <>
      {isLoading && (
        <LoadingComponent/>
      )}
      <Box p="40px 0">
        <Container maxWidth="md">
          <SectionTitleComponent title="ご注文内容" />
          <CartConfirmProductListComponent products={products} isDevicePc={isDevicePc} />
          <Box mx="auto" textAlign="right">
            <Box>
              小計：￥{calculateSubtotal(products).toLocaleString()}<br />
            配送料：￥{calculatePostage(products, shippingInfo.postalCode, shippingInfo.prefecture).toLocaleString()}
            </Box>
            <Divider />
            <Box fontSize="24px" fontWeight="700">
              合計：￥{calculateTotal(products, shippingInfo.postalCode, shippingInfo.prefecture).toLocaleString()}
            </Box>
          </Box>
          <BuyerInfoDisplayComponent buyerInfo={buyerInfo} classes={classes} />
          <ShippingInfoDisplayComponent shippingInfo={shippingInfo} classes={classes} />
          <Box display="flex" justifyContent="space-between" flexDirection={isDevicePc ? "row" : "column-reverse"}>
            <Button variant='outlined' color='primary' onClick={pageBack} className={classes.buttonBig}>注文内容を修正する</Button>
            <Button variant='contained' color='primary' onClick={order} disableElevation className={classes.buttonBig}>注文を確定する</Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default withRouter(connect(
  mapStateToProps
)(CartConfirmContainer))
