import React from 'react'
import {Box} from '@material-ui/core'

const FirstViewComponent = (props) => {
  const {classes, isDevicePc} = props;
  return (
    <Box className={classes.firstView}>
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <Box mx="auto" p={isDevicePc? "40px" : "24px"} maxWidth="440px" width="100%" borderRadius="16px" lineHeight="2" className={classes.firstViewDescription}>
          <img src="/img/logo.svg" className={classes.logo} alt="あきやま園" />
          <Box>
            秋山園は梨産地の鳥取県八頭町で80年続く農園です。農業一筋の先代の園を夫婦2人で引き継ぎました。昨年退職してからは専従者になり、夫、学生・社会人ボランティアの方々と一緒に、梨や柿を作っています。
          <br />秋が深まるにつれて、梨だけではなく柿も美味しくなってきています。
          <br />スーパーでは買えないあんな品種、こんな品種を、面白がって食べていただけたら嬉しいです。
        </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default FirstViewComponent
