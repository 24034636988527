import React from 'react'
import { useForm, FormProvider } from "react-hook-form";
import { Button, Box } from '@material-ui/core'
import BuyerInputComponent from '../../components/cart/buyer_input_component';
import ShippingInputComponent from '../../components/cart/ shipping_input_component';
import SectionTitleComponent from '../../components/common/section_title_component'

const CartFormComponent = (props) => {
  const { products, buyerInfo, shippingInfo, isShippingDefferent, classes} = props;
  const { confirmOrderDetails, onClickShippingCheckbox } = props;

  const methods = useForm();

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(confirmOrderDetails)} noValidate>    
        <SectionTitleComponent title="購入者の情報"/>
        <BuyerInputComponent buyerInfo={buyerInfo} classes={classes}/>
        <SectionTitleComponent title="配送先の情報" />
        <ShippingInputComponent shippingInfo={shippingInfo} classes={classes} isShippingDefferent={isShippingDefferent} onClickShippingCheckbox={onClickShippingCheckbox}/>
        <Box mx="auto" maxWidth="640px">
          <Button
            variant="contained"
            size="large"
            color="primary"
            disableElevation
            disabled={Object.keys(products).length < 1}
            type="submit"
            className={classes.buttonBig}
          >
            注文内容を確認する
        </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default CartFormComponent