import { ADD_PRODUCT_TO_CART, DELETE_PRODUCT_FROM_CART, UPDATE_CART_CONTENTS, INIT_CART } from "../actions/action_types";

const initialState = {};

const cartContents = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART: {
      const { product, count } = action.payload;
      const productList = {...state}
      productList[product.id] = productList[product.id]? productList[product.id] + +count : +count;
      return productList
    }
    case UPDATE_CART_CONTENTS: {
      const {product, count} = action.payload;
      const productList = {...state};
      productList[product.id] = +count;
      return productList
    }
    case DELETE_PRODUCT_FROM_CART: {
      const {product} = action.payload;
      const productList = {...state};
      delete productList[product.id];
      return productList
    }
    case INIT_CART: {
      return initialState;
    }
    default:
      return state;
  }
}

export default cartContents