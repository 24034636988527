import { CONFIRM_SHIPPING_INFO, INIT_SHIPPING_INFO } from "./action_types";

export const confirmShippingInfo = (shippingInfo) => {
  return ({
    type: CONFIRM_SHIPPING_INFO,
    payload: {
      shippingInfo
    }
  })
};

export const initShippingInfo = () => {
  return ({
    type: INIT_SHIPPING_INFO,
    payload: {}
  })
}