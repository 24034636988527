import { ADD_PRODUCT_TO_CART, DELETE_PRODUCT_FROM_CART, UPDATE_CART_CONTENTS, INIT_CART } from "./action_types";

export const addProductToCart = (product, count) => {
  return ({
    type: ADD_PRODUCT_TO_CART,
    payload: {
      product,
      count
    }
  })
};

export const updateCartContents = (product, count) => {
  return ({
    type: UPDATE_CART_CONTENTS,
    payload: {
      product,
      count
    }
  })
};

export const deleteProductFromCart = (product) => {
  return({
    type: DELETE_PRODUCT_FROM_CART,
    payload: {
      product
    }
  })
};

export const initCart = () => {
  return({
    type: INIT_CART,
    pryload: {}
  })
}