import React, { useState }  from 'react';
import { connect } from 'react-redux'
import {updateCartContents, deleteProductFromCart} from "../../actions/product_action"
import { Box, Container, Button } from '@material-ui/core'
import classes from '../../styles/app/cart.module.scss'
import {Link, withRouter} from 'react-router-dom'
import CartContentsListComponent from '../../components/cart/cart_contents_list_component';
import { calculateSubtotal } from '../../helper/common';
import {confirmBuyerInfo} from '../../actions/buyer_action'
import {confirmShippingInfo} from '../../actions/shipping_action'
import CartFormComponent from '../../components/cart/cart_form_component';
import SectionTitleComponent from '../../components/common/section_title_component'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function mapStateToProps(state) {
  return {
    products: state.cartContents,
    buyerInfo: state.buyerInfo,
    shippingInfo: state.shippingInfo
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateCartContents: (product, count) => {dispatch(updateCartContents(product, count))},
    deleteProductFromCart: (product) => {dispatch(deleteProductFromCart(product))},
    confirmBuyerInfo: (buyer) => {dispatch(confirmBuyerInfo(buyer))},
    confirmShippingInfo: (shippingInfo) => {dispatch(confirmShippingInfo(shippingInfo))}
  }
};

const CartContainer = (props) => {
  const { products, buyerInfo, shippingInfo } = props;
  const [isShippingDefferent, setIsShippingDefferent] = useState(shippingInfo.isShippingDefferent);

  // デバイス幅の判定
  const theme = useTheme();
  const isDevicePc = useMediaQuery(theme.breakpoints.up('md'));

  const updateCartContents = (product, count) => {
    props.updateCartContents(product, count);
  };

  const deleteProductFromCart = (product) => {
    props.deleteProductFromCart(product);
  };

  // 配送先が購入者と同じかどうかのチェックボックスイベント
  const onClickShippingCheckbox = (isChecked) => {
    setIsShippingDefferent(isChecked);
  };

  const confirmOrderDetails = (data) => {
    const buyerInfo = {
      name: data.name,
      postalCode: data.postalCode,
      prefecture: data.prefecture,
      city: data.city,
      building: data.building,
      tel: data.tel,
      email: data.email,
      remarks: data.remarks
    }

    let shippingInfo = {};
    if (isShippingDefferent) {
      shippingInfo = {
        isShippingDefferent,
        name: data.shippingName,
        postalCode: data.shippingPostalCode,
        prefecture: data.shippingPrefecture,
        city: data.shippingCity,
        building: data.shippingBuilding,
        tel: data.shippingTel,
      }
    } else {
      shippingInfo = {
        isShippingDefferent,
        name: data.name,
        postalCode: data.postalCode,
        prefecture: data.prefecture,
        city: data.city,
        building: data.building,
        tel: data.tel,
      }
    }
    
    props.confirmBuyerInfo(buyerInfo);
    props.confirmShippingInfo(shippingInfo);
    props.history.push('/confirm');
  };

  return (
    <Box p="40px 0">
      <Container maxWidth="md">
        <Box>
          <Box textAlign="center" mx="auto" mb="64px" maxWidth="640px">
            <Box mb="16px">お買い忘れはございませんか？</Box>
            <Link to={"/"}>
              <Button variant="outlined" color="primary" className={classes.buttonBig}>買い物をつづける</Button>
            </Link>
          </Box>
        </Box>
        <SectionTitleComponent title="カートの中身"/>
        <CartContentsListComponent 
          products={products} 
          updateCartContents={updateCartContents} 
          deleteProductFromCart={deleteProductFromCart}
          isDevicePc={isDevicePc}
        />
        <Box mx="auto" textAlign="right" fontSize="24px" fontWeight="700" maxWidth="800px">
          小計：￥{calculateSubtotal(products).toLocaleString()}
        </Box>
        <Box my="64px" mx="auto" bgcolor="#FDEDCF" borderRadius="8px" maxWidth="640px" p="24px">
          ＊購入に関しての留意事項
          <ul>
            <li className={classes.noteList}>樹上完熟品につき、商品の発送は収穫でき次第が基本です。お時間をいただくことがございますが、ご了承ください。</li>
            <li className={classes.noteList}>獣害などで梨の収穫に影響が出た場合は別の品種を代替品として相談させていただく場合がございます。</li>
            <li className={classes.noteList}>決済方法は銀行振込のみ、受け付けております。注文完了画面または注文完了メールに記載されている口座にお振込ください。</li>
          </ul>
        </Box>
        <CartFormComponent  
          products={products}
          buyerInfo={buyerInfo} 
          shippingInfo={shippingInfo} 
          classes={classes}
          confirmOrderDetails={confirmOrderDetails}
          isShippingDefferent={isShippingDefferent}
          onClickShippingCheckbox={onClickShippingCheckbox}
        />
      </Container>
    </Box>
  )
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CartContainer))