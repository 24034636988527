import React from 'react'
import { Box } from '@material-ui/core'
import classes from '../../styles/app/components/footer.module.scss'

const FooterComponent = () => {
  return (
    <Box py="16px" px="24px" display="flex" flexWrap="wrap" borderTop="8px solid #3F522F">
      <Box my="8px" pr="40px">
        <img src="/img/logo.svg" className={classes.logo} alt="あきやま園" />
      </Box>
      <Box my="8px" pr="40px">〒680-0612<br />鳥取県八頭郡八頭町志谷752-1</Box>
      <Box my="8px">
        お問い合わせ先
        <br/>090-9731-4337
        <br />akiyamaen3147@yahoo.co.jp
      </Box>
    </Box>
  )
};

export default FooterComponent