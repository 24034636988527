import React from 'react'
import { Box, Card, CardContent, CardActions, TextField, MenuItem, Button, Typography, CardMedia } from '@material-ui/core'
import classes from '../../styles/app/components/product_card_small.module.scss'

const ProductCardSmallComponent = (props) => {
  const {product, count, isDevicePc} = props;
  return (
    <Card className={classes.card} variant="outlined">
      <Box display="flex" flexDirection={isDevicePc? "row" : "column"} alignItems={isDevicePc? "center" : "flex-start"} justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <CardMedia
            className={classes.cardMedia}
            image={product.imagePath}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography component="div" variant="h5">
              {product.name}
            </Typography>
            <Typography variant="h5" component="div">
              ¥ {product.price.toLocaleString()}
            </Typography>
          </CardContent>
        </Box>
        <CardActions className={classes.cardActions}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="body2" display="inline">数量：</Typography>
            <TextField
              className={classes.cardInput}
              id="select outlined-size-small"
              variant="outlined"
              size="small"
              value={count}
              onChange={(e) => props.addProductToCart(product, e.target.value)}
              select
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </TextField>
          </Box>
          <Button 
            variant="contained" 
            color="default" 
            className={classes.button} 
            onClick={() => props.deleteProductFromCart(product)} 
            disableElevation
          >
            削除
          </Button>
        </CardActions>
      </Box>
    </Card>
  )
};

export default ProductCardSmallComponent