import products from '../data/products'
import Prefectures from '../data/prefectures';
import RemoteIslandList　from '../data/remoteIslandList'


// 郵便番号の整形
export const formatPostalCode = (postalCode) => {
  return zenkaku2Hankaku(postalCode).replace(/\D/g, "");
};

// 重量ごとの箱数の計算
export const getBoxNum = (products) => {
  let num3kg = 0
  let num4kg = 0
  let num5kg = 0
  let num7kg500m = 0
  let num8kg = 0
  let num10kg = 0
  let num12kg = 0
  Object.keys(products).forEach((id) => {
    const product = getProductFromId(id);
    if (product.weight === 3){
      num3kg += products[id];
    }
    if (product.weight === 4){
      num4kg += products[id];
    }
    if (product.weight === 5){
      num5kg += products[id];
    }
    if (product.weight === 7.5){
      num7kg500m += products[id];
    }
    if (product.weight === 8){
      num8kg += products[id];
    }
    if (product.weight === 10){
      num10kg += products[id];
    }
    if (product.weight === 12){
      num12kg += products[id];
    }
  })
  return {
    num3kg: num3kg,
    num4kg: num4kg,
    num5kg: num5kg,
    num7kg500m: num7kg500m,
    num8kg: num8kg,
    num10kg: num10kg,
    num12kg: num12kg,
  };
};

// 全角to半角
export const zenkaku2Hankaku = (str) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}

//　商品IDから商品の情報を取得する
export const getProductFromId = (id) => {
  let product;
  switch (id.slice(0,1)) {
    case '1':
      products.pear.varieties.forEach((p) => {
        if (p.id === +id) {
          product = p;
        }
      });
      break;
    case '2':
      products.persimmon.varieties.forEach((p) => {
        if (p.id === +id) {
          product = p;
        }
      });
      break;

    default:
      product = {};
  }
  return product
};

// カートに入っている商品の小計を計算する
export const calculateSubtotal = (products) => {
  let subtotal = 0;
  Object.keys(products).forEach((id) => {
    const product = getProductFromId(id);
    subtotal += product.price * products[id];
  })
  return subtotal
};

// カート内容をAPIで使用する形に変換する
export const convertCartContentsToBody = (products) => {
  const productList = {};
  Object.keys(products).forEach((id) => {
    const product = getProductFromId(id);
    productList[product.label + "Num"] = products[id];
  })
  return productList
}

// 送料を計算する
export const calculatePostage = (products, postalCode, prefCode ) => {
  let postagePerSet = 1200;
  let {num3kg, num4kg, num5kg, num7kg500m, num8kg, num10kg, num12kg} = getBoxNum(products);
  let setNum = Math.ceil((num3kg * 3 + num4kg * 4 + num5kg * 5 + num7kg500m * 7.5+ num8kg * 8+ num10kg * 10+ num12kg * 12) / 10);
  let formatedPostalCode = formatPostalCode(postalCode) 
  let remoteIslandFlag = false;
  const tohokuPrefCodeList = ["2","3","4","5","6","7"];
  
  if(1 === setNum && 1 === num3kg && 0 === num4kg && 0 === num5kg && 0 === num7kg500m && 0 === num8kg && 0 === num10kg && 0 === num12kg) {
    postagePerSet = 1000;
  }
  if(formatedPostalCode && RemoteIslandList.indexOf(formatedPostalCode) >= 0) {
    remoteIslandFlag = true;
    postagePerSet += 500;
  }
  if(remoteIslandFlag === false && tohokuPrefCodeList.indexOf(prefCode) >= 0) {
    postagePerSet += 300;
  }
  return postagePerSet * setNum
};

// カートに入っている商品の小計と送料の合計を計算する
export const calculateTotal = (products, postalCode, prefCode) => {
  let total = 0;
  let subtotal = calculateSubtotal(products);
  let postage = calculatePostage(products, postalCode, prefCode);
  total = subtotal + postage;
  return total
};

// 都道府県valueからlabelに変更
export const convertPrefectureValueToLabel = (value) => {
  let prefectureLabel = '';
  Prefectures.forEach((v) => {
    if (v.value === value) {
      prefectureLabel = v.label
    }
  })
  return prefectureLabel
}

// 注文商品ラベルの取得
export const getItemLabels = (products) => {
  let itemLabels = '';
  Object.keys(products).forEach((id) => {
    const product = getProductFromId(id);
    itemLabels += product.name + ' ' + products[id].toString(10) + '箱 / ';
  })
  itemLabels = itemLabels.slice(0,-3);
  return itemLabels
};

