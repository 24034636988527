import React from 'react'
import ProductCardComponent from './product_card_component'
import { Grid, Typography, Box } from '@material-ui/core';

const ProductListComponent = (props) => {
  const {isDevicePc, cartContents, products} = props;
  const addProductToCart = (product, count) => {
    props.addProductToCart(product, count);
  }
  const contents = products.varieties.filter(product => product.isDisplayed === true).sort((a, b) => (a.isSoldOut - b.isSoldOut)).map((product, i) => {
    let count = cartContents[product.id]? cartContents[product.id]: '0'; 
    return (
      <Grid item xs={isDevicePc? 4 : 12} key={i}>
        <ProductCardComponent product={product} count={count} addProductToCart={addProductToCart}/>
      </Grid>
    )
  });
  return (
    <Box mb="32px">
      <Box mb="16px">
        <Typography gutterBottom variant="h3" component="h3" fontWeight="700">
          {props.products.name}
        </Typography>
        <Typography gutterBottom color="textSecondary" variant="body1" component="p">
          {props.products.note}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {contents}
      </Grid>
    </Box>
  )
};

export default ProductListComponent
