import { CONFIRM_SHIPPING_INFO, INIT_SHIPPING_INFO } from "../actions/action_types";

const initialState = {
  isShippingDefferent: false,
  name: '',
  postalCode: '',
  prefecture: '',
  city: '',
  building: '',
  tel: ''
};

const shippingInfo = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_SHIPPING_INFO: {
      const { shippingInfo } = action.payload;
      let newState = { ...state }
      newState = shippingInfo;
      return newState
    }
    case INIT_SHIPPING_INFO: {
      return initialState;
    }
    default:
      return state;
  }
}

export default shippingInfo