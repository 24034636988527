import React from 'react'
import classes from '../../styles/app/components/loading.module.scss'

const LoadingComponent = (props) => {
  return (
    <div className={classes.loading}>
      <div className={classes.loadingContents}>
        <div className={classes.skFoldingCube}>
          <div className={classes.skCube}></div>
          <div className={classes.skCube2 + " " + classes.skCube}></div>
          <div className={classes.skCube4 + " " + classes.skCube}></div>
          <div className={classes.skCube3 + " " + classes.skCube}></div>
        </div>
        <div className={classes.loadingText}>注文しています</div>
      </div>
    </div>
  )
}

export default LoadingComponent