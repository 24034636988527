import React from 'react'
import { Container, Box } from '@material-ui/core'
import { convertPrefectureValueToLabel } from '../../helper/common';

const ShippingInfoDisplayComponent = (props) => {
  const { shippingInfo, classes } = props;
  return (
    <Box my="64px">
      <Container maxWidth="sm" my="64px">
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan="2" className={classes.th}>配送先の情報</th>
            </tr>
          </thead>
          {shippingInfo.isShippingDefferent
            ? (
              <tbody>
                <tr>
                  <td className={classes.label}>氏名</td>
                  <td>{shippingInfo.name}</td>
                </tr>
                <tr>
                  <td className={classes.label}>郵便番号</td>
                  <td>{shippingInfo.postalCode}</td>
                </tr>
                <tr>
                  <td className={classes.label}>都道府県</td>
                  <td>{convertPrefectureValueToLabel(shippingInfo.prefecture)}</td>
                </tr>
                <tr>
                  <td className={classes.label}>市区町村/番地</td>
                  <td>{shippingInfo.city}</td>
                </tr>
                <tr>
                  <td className={classes.label}>アパート・<br />マンション名</td>
                  <td>{shippingInfo.building ? shippingInfo.building : "-"}</td>
                </tr>
                <tr>
                  <td className={classes.label}>電話番号</td>
                  <td>{shippingInfo.tel}</td>
                </tr>
              </tbody>
            )
            : (
              <tbody>
                <tr>
                  <td colSpan="2">購入者の情報と同じ</td></tr>
              </tbody>
            )
          }
        </table>
      </Container>
    </Box>
  )
}

export default ShippingInfoDisplayComponent