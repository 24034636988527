import React from 'react'
import {TextField, Box, Typography, FormControlLabel, Checkbox} from '@material-ui/core'
import { useFormContext } from "react-hook-form";
import PrefectureSelectComponent from '../common/prefecture_select_component';

const ShippingInputComponent = (props) => {
  const {shippingInfo, isShippingDefferent, classes} = props;
  const { register, errors } = useFormContext();
  const { onClickShippingCheckbox } = props;

  return (
    <Box maxWidth="640px" mx="auto" mb="64px" width="100%">
      <Typography variant="body1">購入者と異なる住所に配送する場合は下のにチェックをつけてください。</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isShippingDefferent}
            onChange={(e) => onClickShippingCheckbox(e.target.checked)}
            name="isShippingDefferent"
            color="primary"
          />
        }
        label="購入者と異なる住所に配送する"
      />
      {isShippingDefferent && (
        <Box>
          <Box my="24px">
            <Typography variant="body1">お名前 *</Typography>
            <TextField
              defaultValue={shippingInfo.name}
              variant="outlined"
              size="small"
              placeholder="例）山田花子"
              name="shippingName"
              error={errors.shippingName ? true : false}
              inputRef={register({ required: true })}
              helperText={errors.shippingName && "必須項目です。入力してください"}
              required
              className={classes.inputMd}
            />
          </Box>
          <Box my="24px">
            <Typography variant="body1">郵便番号 *</Typography>
            <TextField
              defaultValue={shippingInfo.postalCode}
              variant="outlined"
              size="small"
              placeholder="例）680-8570"
              name="shippingPostalCode"
              error={errors.shippingPostalCode ? true : false}
              inputRef={register({ required: true })}
              helperText={errors.shippingPostalCode && "必須項目です。入力してください"}
              required
              className={classes.inputMd}
            />
          </Box>
          <Box my="24px">
            <Typography variant="body1">都道府県 *</Typography>
            <PrefectureSelectComponent classes={classes} value={shippingInfo.prefecture} name={"shippingPrefecture"} />
          </Box>
          <Box my="24px">
            <Typography variant="body1">市区町村/番地 *</Typography>
            <TextField
              defaultValue={shippingInfo.city}
              variant="outlined"
              size="small"
              placeholder="例）鳥取市○○町○丁目"
              name="shippingCity"
              error={errors.shippingCity ? true : false}
              inputRef={register({ required: true })}
              helperText={errors.shippingCity && "必須項目です。入力してください"}
              required
              className={classes.inputMd}
            />
          </Box>
          <Box my="24px">
            <Typography variant="body1">アパート・マンション名</Typography>
            <TextField
              defaultValue={shippingInfo.building}
              variant="outlined"
              size="small"
              placeholder="例）○○マンション ○号室"
              name="shippingBuilding"
              error={errors.shippingBuilding ? true : false}
              inputRef={register({ required: false })}
              required
              className={classes.inputMd}
            />
          </Box>
          <Box my="24px">
            <Typography variant="body1">電話番号 *</Typography>
            <TextField
              defaultValue={shippingInfo.tel}
              variant="outlined"
              size="small"
              placeholder="例）09000000000"
              name="shippingTel"
              error={errors.shippingTel ? true : false}
              inputRef={register({ required: true })}
              helperText={errors.shippinTel && "必須項目です。入力してください"}
              required
              className={classes.inputMd}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ShippingInputComponent