import React from 'react'
import classes from '../../styles/app/top.module.scss'
import ProductListComponent from '../../components/top/product_list_component'
import FirstViewComponent from '../../components/top/first_view'
import { Container, Box, Button } from '@material-ui/core'
import products from '../../data/products'
import { connect } from 'react-redux'
import {addProductToCart} from "../../actions/product_action"
import { Link, withRouter } from 'react-router-dom'
import SectionTitleComponent from '../../components/common/section_title_component'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function mapStateToProps(state) {
  return {
    cartContents: state.cartContents
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addProductToCart: (product, count) => {dispatch(addProductToCart(product, count))}
  }
};

const TopContainer = (props) => {

  const addProductToCart = (product, count) => {
    props.addProductToCart(product, count);
    props.history.push("/cart");
  };

  // デバイス幅の判定
  const theme = useTheme();
  const isDevicePc = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <FirstViewComponent classes={classes} isDevicePc={isDevicePc}/>
      <Container maxWidth="md">
        <Box my="64px">
          <Box my="64px" mx="auto" bgcolor="#FDEDCF" borderRadius="8px" maxWidth="640px" p="24px">
            FAXでのご注文も承っております。
            <br/>ご希望の商品名、ご購入者様の情報（お名前・住所・電話番号・メールアドレス）、発送先の情報（お名前・住所・電話番号）を記載の上、下記の番号にご連絡ください。
            <br />FAX：0858-84-3147
          </Box>
          <SectionTitleComponent title="商品一覧" />
          <Box my="64px" mx="auto" bgcolor="#FDEDCF" borderRadius="8px" maxWidth="640px" p="24px">
            各商品の値段は「購入はこちら」のボタンから確認してください。
          </Box>
          <ProductListComponent 
            cartContents={props.cartContents} 
            products={products.pear} 
            addProductToCart={addProductToCart.bind(this)}
            isDevicePc={isDevicePc}
          />
          <ProductListComponent 
            cartContents={props.cartContents} 
            products={products.persimmon} 
            addProductToCart={addProductToCart.bind(this)}
            isDevicePc={isDevicePc}
          />
        </Box>
        <Box my="64px" mx="auto" maxWidth="640px">
          <Link to="/cart">
            <Button variant="outlined" color="primary" className={classes.buttonBig}>カートを見る</Button>
          </Link>
        </Box>
        <Box my="64px" mx="auto" bgcolor="#FDEDCF" borderRadius="8px" maxWidth="640px" p="24px">
          ＊購入に関しての留意事項
          <ul>
            <li className={classes.noteList}>樹上完熟品につき、商品の発送は収穫でき次第が基本です。お時間をいただくことがございますが、ご了承ください。</li>
            <li className={classes.noteList}>獣害などで梨の収穫に影響が出た場合は別の品種を代替品として相談させていただく場合がございます。</li>
            <li className={classes.noteList}>決済方法は銀行振込のみ、受け付けております。注文完了画面または注文完了メールに記載されている口座にお振込ください。</li>
          </ul>
        </Box>
      </Container>
    </Box>
  )
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopContainer))
