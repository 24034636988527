import { CONFIRM_BUYER_INFO, INIT_BUYER_INFO } from "./action_types";

export const confirmBuyerInfo = (buyerInfo) => {
  return ({
    type: CONFIRM_BUYER_INFO,
    payload: {
      buyerInfo
    }
  })
};

export const initBuyerInfo = () => {
  return ({
    type: INIT_BUYER_INFO,
    payload: {}
  })
}