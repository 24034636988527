import React from 'react'
import {TextField, Box, Typography} from '@material-ui/core'
import { useFormContext } from "react-hook-form";
import PrefectureSelectComponent from '../common/prefecture_select_component';

const BuyerInputComponent = (props) => {
  const {buyerInfo, classes} = props;
  const { register, errors } = useFormContext();
  return (
    <Box maxWidth="640px" mx="auto" my="64px" width="100%">
      <Box my="24px">
        <Typography variant="body1">お名前 *</Typography>
        <TextField
          defaultValue={buyerInfo.name}
          variant="outlined"
          size="small"
          placeholder="例）山田太郎"
          name="name"
          error={errors.name ? true : false}
          inputRef={register({ required: true })}
          helperText={errors.name && "必須項目です。入力してください。"}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">郵便番号 *</Typography>
        <TextField
          defaultValue={buyerInfo.postalCode}
          variant="outlined"
          size="small"
          placeholder="例）680-8570"
          name="postalCode"
          error={errors.postalCode ? true : false}
          inputRef={register({ required: true })}
          helperText={errors.postalCode && "必須項目です。入力してください"}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">都道府県 *</Typography>
        <PrefectureSelectComponent classes={classes} value={buyerInfo.prefecture} name={"prefecture"}/>
      </Box>
      <Box my="24px">
        <Typography variant="body1">市区町村/番地 *</Typography>
        <TextField
          defaultValue={buyerInfo.city}
          variant="outlined"
          size="small"
          placeholder="例）鳥取市○○町○丁目"
          name="city"
          error={errors.city ? true : false}
          inputRef={register({ required: true })}
          helperText={errors.city && "必須項目です。入力してください"}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">アパート・マンション名</Typography>
        <TextField
          defaultValue={buyerInfo.city}
          variant="outlined"
          size="small"
          placeholder="例）○○マンション ○号室"
          name="building"
          error={errors.building ? true : false}
          inputRef={register({ required: false })}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">電話番号 *</Typography>
        <TextField
          defaultValue={buyerInfo.tel}
          variant="outlined"
          size="small"
          placeholder="例）09000000000"
          name="tel"
          error={errors.tel ? true : false}
          inputRef={register({ required: true })}
          helperText={errors.tel && "必須項目です。入力してください"}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">メールアドレス *</Typography>
        <TextField
          defaultValue={buyerInfo.email}
          variant="outlined"
          size="small"
          placeholder="例）samplemail@sample.com"
          name="email"
          error={errors.email ? true : false}
          inputRef={register({ required: true })}
          helperText={errors.email && "必須項目です。入力してください"}
          required
          className={classes.inputMd}
        />
      </Box>
      <Box my="24px">
        <Typography variant="body1">備考欄</Typography>
        <TextField
          defaultValue={buyerInfo.remarks}
          variant="outlined"
          size="small"
          multiline
          rows={5}
          placeholder="ご質問など"
          name="remarks"
          inputRef={register}
          className={classes.inputLg}
        />
      </Box>
    </Box>
  )
};

export default BuyerInputComponent